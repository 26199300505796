:root {
  --wh-field-textinput-height: 44px;
  --wh-label-font-size: 15px;
}

.wh-form__fieldgroup:not(.wh-form__fieldgroup--hidden) {
  display: flex;
}

.wh-form__fieldgroup {
  align-items: baseline;
}

.wh-form__label, .wh-form__optiondata,
.wh-form__optionlabel,
.wh-form__subfieldlabel {
  display: inline-block;
}

.wh-form__label {
  flex-shrink: 0;
}

.wh-form__fields {
  flex: 1 0 0px;
}

.wh-form__fieldline {
  width: 100%;
  align-items: baseline;
  flex-wrap: wrap;
}

.wh-form__fieldline:not(.wh-form__fieldline--hidden) {
  display: inline-flex;
}

.wh-form__fieldline--nowrap:not(.wh-form__fieldline--hasinfotext) {
  flex-wrap: nowrap;
}

.wh-form__fieldgroup--horizontal .wh-form__fields {
  display: flex;
  flex-wrap: wrap;
}
.wh-form__fieldgroup--horizontal .wh-form__fieldline {
  flex: none;
  width: auto;
  max-width: 100%;
}

.wh-form__infotextwrapper {
  flex: 0 0 100%;
}

.wh-form__optiondata {
  display: inline-flex;
  width: 100%;
  align-items: baseline;
  flex-grow: 1;
  flex-wrap: wrap;
}
.wh-form__optiondata--vertical {
  width: 0;
}

.wh-form__subfield {
  display: flex;
  flex-grow: 1;
  align-items: baseline;
}

.wh-form__fieldline > input[type=checkbox],
.wh-form__fieldline > input[type=radio] {
  flex-shrink: 0;
}

.wh-form__fieldline > textarea {
  resize: vertical;
}

::-webkit-input-placeholder {
  opacity: 0.5;
  color: inherit;
}

::-moz-placeholder {
  opacity: 0.5;
  color: inherit;
}

:-ms-input-placeholder {
  opacity: 0.5;
  color: inherit;
}

input:-moz-placeholder {
  opacity: 0.5;
  color: inherit;
}

.pakhuisoost-dialog {
  background: white;
  padding: 20px;
  width: 729px;
}
.pakhuisoost-dialog__modalbg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pakhuisoost-dialog__modalbg:before {
  display: inline-block;
  content: "";
  vertical-align: middle;
  line-height: 100%;
  height: 100%;
  width: 0;
}
.pakhuisoost-dialog__holder {
  text-align: left;
  display: inline-block;
  white-space: normal;
  font-family: inherit;
  color: inherit;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}
.pakhuisoost-dialog__modalbg {
  z-index: 1001;
}
.pakhuisoost-dialog .dialog-close {
  position: absolute;
  right: 20px;
  top: 15px;
  color: inherit;
  width: 30px;
  height: 30px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
@media (max-width: 729px) {
  .pakhuisoost-dialog {
    width: 100%;
  }
}

.rpc__busyoverlay {
  display: none;
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
}
html.dompack--busymodal .rpc__busyoverlay {
  display: block;
}
.rpc__busyoverlay .message {
  display: block;
  position: absolute;
  font-size: 30px;
  top: 50%;
  left: 0;
  width: 100%;
  padding-left: 25px;
  text-align: center;
  margin-top: -90px;
}
.rpc__busyoverlay .spinner {
  display: block;
  position: absolute;
  font-size: 80px;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px;
  opacity: 0.8;
}

.wh-form__pagination {
  list-style-type: none;
  margin: 0;
  padding: 20px 0;
  display: flex;
  flex-flow: row;
  color: #283477;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  position: relative;
  justify-content: flex-start;
}
.wh-form__pagination::before {
  content: "";
  border-top: 1px solid #283477;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
}
.wh-form__pagination::after {
  content: "";
  display: block;
  background-color: #283477;
  position: absolute;
  top: 50%;
  margin-top: -3px;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  right: 0;
  z-index: -1;
}
.wh-form__pagination > li.active {
  flex-grow: 100;
}
.wh-form__pagination > li {
  position: relative;
  display: flex;
  transition: all 0.5s;
  flex: 0 0 auto;
  cursor: default;
}
.wh-form__pagination > li + li {
  margin-left: 15px;
}
.wh-form__pagination > li > span {
  display: flex;
  border-radius: 50%;
  min-width: 46px;
  height: 46px;
  width: 46px;
  border: 1px solid #283477;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  transition: color 0.3s, background-color 0.3s;
  position: relative;
}
.wh-form__pagination > li.active + li {
  cursor: pointer;
}
.wh-form__pagination > li.previous > span {
  background-color: #E5F3FA;
  cursor: pointer;
}
.wh-form__pagination > li.previous > span::after {
  background-color: inherit;
  content: "\f00c";
  display: block;
  position: absolute;
  font-weight: 300;
  text-align: center;
  font-size: 16px;
  background-color: #E5F3FA;
  padding-top: 7px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  font-family: "Font Awesome 5 Pro";
}
.wh-form__pagination > li.active > span {
  background-color: #283477;
  color: #fff;
}
.wh-form__pagination > li:last-child {
  justify-content: flex-end;
}
.wh-form__pagination.finished > li.previous > span {
  border-color: #1A7DD7;
  color: #1A7DD7;
}
.wh-form__pagination.finished > li.previous > span::after {
  background-color: #F5FFFF;
}
.wh-form__pagination.finished > li.active > span {
  border-color: #026A16;
  background-color: #026A16;
  cursor: default;
}
.wh-form__pagination.finished > li.previous > span {
  cursor: default;
}
@media (max-width: 550px) {
  .wh-form__pagination {
    font-size: 16px;
    line-height: 20px;
    padding: 5px 0;
  }
  .wh-form__pagination > li + li {
    margin-left: 10px;
  }
  .wh-form__pagination > li > span {
    min-width: 34px;
    height: 34px;
    width: 34px;
  }
  .wh-form__pagination > li.previous > span::after {
    font-size: 14px;
    padding-top: 6px;
  }
}

.wh-form__richtext .centercontent {
  padding-right: 0;
  padding-left: 0;
}

.passwordfields {
  display: flex;
  padding-bottom: 30px;
}
.passwordfields > .wh-form__label {
  flex: 0 0 195px;
  max-width: 195px;
  min-width: 195px;
  font-size: 15px;
  padding-top: 12px;
}
.passwordfields > .wh-form__label:not(empty)::after {
  content: "*";
}
.passwordfields__fieldgroups {
  max-width: calc(100% - 195px);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.passwordfields .wh-form__fieldgroup {
  flex: 1 1 50%;
  max-width: calc(50% - 15px);
}
.passwordfields .wh-form__fieldgroup > .wh-form__fields {
  max-width: 100% !important;
}
.passwordfields .note {
  flex: none;
  width: 100%;
}
@media (max-width: 550px) {
  .passwordfields {
    display: block;
  }
  .passwordfields > .wh-form__label {
    max-width: 100%;
    min-width: 0;
    padding-bottom: 3px;
  }
  .passwordfields__fieldgroups, .passwordfields .wh-form__fieldgroup {
    max-width: 100%;
    display: block;
  }
}

/* exceptions specific for donation form */
html.donatepage .wh-form.wh-styledinput .wh-form__fieldgroup > .wh-form__label {
  flex: 0 0 145px;
  max-width: 145px;
  min-width: 145px;
}
html.donatepage .wh-form.wh-styledinput .wh-form__fieldgroup > .wh-form__fields {
  max-width: calc(100% - 145px);
}
@media (max-width: 1100px) {
  html.donatepage .wh-form.wh-styledinput .wh-form__fieldgroup > .wh-form__label {
    flex: 0 0 195px;
    max-width: 195px;
    min-width: 195px;
  }
  html.donatepage .wh-form.wh-styledinput .wh-form__fieldgroup > .wh-form__fields {
    max-width: calc(100% - 195px);
  }
}

/* infotext below input */
.wh-form__infotextwrapper {
  width: 100%;
  outline: none;
}
.wh-form__infotextwrapper p {
  margin-bottom: 10px;
}
.wh-form__infotextwrapper .wh-form__infotext {
  color: #666;
  font-style: italic;
  font-size: 15px;
  background-color: #F5F5F5;
  border-radius: 0 0 3px 3px;
  padding: 10px 15px;
  width: 100%;
}
.wh-form__infotextwrapper .wh-form__infotext > *:last-child {
  margin-bottom: 0;
}

.wh-form__fieldline--nowrap.wh-form__fieldline--hasinfotext {
  flex-wrap: wrap;
}

.wh-form__optiondata + .wh-form__infotextwrapper {
  margin-top: 10px;
  flex: 0 0 100%;
}

.wh-form.wh-styledinput {
  font-size: 16px;
  /* css styled pulldown */
  /* radio / checkbox styling */
}
.wh-form.wh-styledinput .wh-anchor {
  margin-top: -120px;
}
.wh-form.wh-styledinput.withpagination .wh-anchor {
  margin-top: -210px; /* extra space for pagination */
}
.wh-form.wh-styledinput hr {
  display: block;
  border: 0 none;
  border-top: 1px solid #C5C5C5;
  margin: 30px 0 25px;
}
.wh-form.wh-styledinput h3 {
  font-size: 21px;
}
.wh-form.wh-styledinput h2 {
  border-bottom: 1px solid #C5C5C5;
  padding-bottom: 15px;
}
.wh-form.wh-styledinput h2.wh-form__grouptitle {
  border-bottom: 0 none;
  padding-bottom: 0;
  font-size: 20px;
}
@media (max-width: 356px) {
  .wh-form.wh-styledinput h2.wh-form__grouptitle {
    font-size: 18px;
  }
}
.wh-form.wh-styledinput .wh-form__arrayinput {
  width: 1px;
  padding: 0;
  min-height: 0;
}
.wh-form.wh-styledinput .wh-form__fieldgroup {
  margin-bottom: 15px;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--textarea > .wh-form__label,
.wh-form.wh-styledinput .wh-form__fieldgroup--custom > .wh-form__label {
  align-self: flex-start;
  margin-top: 4px;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--required > .wh-form__label::after {
  display: inline-block;
  content: "*";
}
.wh-form.wh-styledinput .wh-form__prefix {
  padding-right: 10px;
  align-self: center;
  font-size: 15px;
}
.wh-form.wh-styledinput .wh-form__suffix {
  padding-left: 10px;
  align-self: center;
  font-size: 15px;
}
.wh-form.wh-styledinput .wh-form__optionlabel, .wh-form.wh-styledinput .wh-form__subfield > label {
  padding-right: 15px;
  font-size: 15px;
}
.wh-form.wh-styledinput .wh-form__label:empty::after {
  display: none;
}
.wh-form.wh-styledinput .wh-form__fieldgroup > .wh-form__label {
  flex: 0 0 195px;
  max-width: 195px;
  min-width: 195px;
  font-size: 15px;
  padding-right: 5px;
}
.wh-form.wh-styledinput .wh-form__fieldgroup > .wh-form__fields {
  max-width: calc(100% - 195px);
}
.wh-form.wh-styledinput .wh-form__fieldgroup--richtext > .wh-form__fields {
  max-width: 100%;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--richtext > .wh-form__fields .wh-form__fieldline {
  display: block;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--array .wh-form__arrayrow {
  position: relative;
  border-bottom: 1px solid #C5C5C5;
  margin-bottom: 10px;
}
.wh-form.wh-styledinput .wh-form__arraydelete {
  align-self: flex-start;
  position: absolute;
  right: 0;
  top: -3px;
  font-size: 18px;
  color: #283477;
  background-color: transparent;
  cursor: pointer;
}
.wh-form.wh-styledinput .wh-form__arraydelete::before {
  font-family: "Font Awesome 5 Pro";
  content: "\f2ed"; /* trash-alt */
}
.wh-form.wh-styledinput .wh-form__arrayadd {
  background-color: transparent;
  background-color: #283477;
  cursor: pointer;
  font-size: 16px;
  border-radius: 3px;
  display: inline-flex;
  padding: 4px 10px;
  align-items: center;
}
.wh-form.wh-styledinput .wh-form__arrayadd::before {
  content: "Add";
}
html[lang=nl] .wh-form.wh-styledinput .wh-form__arrayadd::before {
  content: "Toevoegen";
}
html[lang=fr] .wh-form.wh-styledinput .wh-form__arrayadd::before {
  content: "Ajouter";
}
.wh-form.wh-styledinput .wh-form__arrayadd::after {
  padding-left: 10px;
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f067"; /* plus */
}
.wh-form.wh-styledinput .wh-form__fieldgroup--array.subfieldscolumns .wh-form__arrayrow {
  display: flex;
  justify-content: space-between;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--array.subfieldscolumns .wh-form__arrayrow .wh-form__fieldgroup--textarea {
  flex: 1 1 100%;
  padding: 0 30px 0 20px;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--array.subfieldscolumns .wh-form__arrayrow .wh-form__fieldgroup--textarea textarea, .wh-form.wh-styledinput .wh-form__fieldgroup--array.subfieldscolumns .wh-form__arrayrow .wh-form__fieldgroup--textarea .wh-form__fields, .wh-form.wh-styledinput .wh-form__fieldgroup--array.subfieldscolumns .wh-form__arrayrow .wh-form__fieldgroup--textarea .wh-form__fieldline {
  height: 100%;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--array.subfieldscolumns .wh-form__arrayrow .wh-form__fieldgroup--imgedit + .wh-form__fieldgroup--textarea textarea {
  max-height: 200px;
}
.wh-form.wh-styledinput .wh-form__fieldgroup.labelabove {
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  align-items: flex-start;
}
.wh-form.wh-styledinput .wh-form__fieldgroup.labelabove > .wh-form__label {
  padding-bottom: 10px;
}
.wh-form.wh-styledinput .wh-form__fieldgroup.labelabove .wh-form__subfield {
  flex-wrap: wrap;
}
.wh-form.wh-styledinput .wh-form__fieldgroup.labelabove > .wh-form__label, .wh-form.wh-styledinput .wh-form__fieldgroup.labelabove > .wh-form__fields {
  flex: none;
  max-width: calc(100vw - 40px);
  min-width: 0;
}
.wh-form.wh-styledinput .wh-form__fieldgroup.labelabove .wh-form__fields {
  width: 100%;
}
@media (max-width: 550px) {
  .wh-form.wh-styledinput .wh-form__fieldgroup {
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    align-items: flex-start;
  }
  .wh-form.wh-styledinput .wh-form__fieldgroup > .wh-form__label {
    padding-bottom: 3px;
    padding-right: 0;
  }
  .wh-form.wh-styledinput .wh-form__fieldgroup .wh-form__subfield {
    flex-wrap: wrap;
  }
  .wh-form.wh-styledinput .wh-form__fieldgroup > .wh-form__label, .wh-form.wh-styledinput .wh-form__fieldgroup > .wh-form__fields {
    flex: none !important;
    max-width: calc(100vw - 40px) !important;
    min-width: 0 !important;
  }
  .wh-form.wh-styledinput .wh-form__fields {
    width: 100%;
  }
}
.wh-form.wh-styledinput select + .wh-form__subfield > label,
.wh-form.wh-styledinput .wh-form__pulldown-styled + .wh-form__subfield > label {
  padding-left: 10px;
}
.wh-form.wh-styledinput input, .wh-form.wh-styledinput select:not(.wh-rtd__toolbarstyle),
.wh-form.wh-styledinput textarea,
.wh-form.wh-styledinput .wh-form__dateinputgroup,
.wh-form.wh-styledinput .wh-form__timeinputgroup {
  border: 1px solid #979797;
  min-height: 44px;
  font-family: inherit;
  font-size: 16px;
  line-height: 20px;
  padding: 12px 15px;
  max-width: 100%;
  color: inherit;
  flex: 1;
  border-radius: 3px;
  background-color: #fff;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
}
.wh-form.wh-styledinput input:disabled, .wh-form.wh-styledinput input[data-wh-form-disabled], .wh-form.wh-styledinput select:not(.wh-rtd__toolbarstyle):disabled, .wh-form.wh-styledinput select:not(.wh-rtd__toolbarstyle)[data-wh-form-disabled],
.wh-form.wh-styledinput textarea:disabled,
.wh-form.wh-styledinput textarea[data-wh-form-disabled],
.wh-form.wh-styledinput .wh-form__dateinputgroup:disabled,
.wh-form.wh-styledinput .wh-form__dateinputgroup[data-wh-form-disabled],
.wh-form.wh-styledinput .wh-form__timeinputgroup:disabled,
.wh-form.wh-styledinput .wh-form__timeinputgroup[data-wh-form-disabled] {
  color: #C5C5C5;
  border-color: #C5C5C5;
  background-color: #F2F2F2;
  box-shadow: none;
}
.wh-form.wh-styledinput input:focus-within, .wh-form.wh-styledinput select:not(.wh-rtd__toolbarstyle):focus-within,
.wh-form.wh-styledinput textarea:focus-within,
.wh-form.wh-styledinput .wh-form__dateinputgroup:focus-within,
.wh-form.wh-styledinput .wh-form__timeinputgroup:focus-within {
  border-color: #283477;
  box-shadow: 0 0 0 3px rgba(40, 52, 119, 0.15);
}
.wh-form.wh-styledinput input:focus, .wh-form.wh-styledinput select:not(.wh-rtd__toolbarstyle):focus,
.wh-form.wh-styledinput textarea:focus,
.wh-form.wh-styledinput .wh-form__dateinputgroup:focus,
.wh-form.wh-styledinput .wh-form__timeinputgroup:focus {
  border-color: #283477;
  box-shadow: 0 0 0 3px rgba(40, 52, 119, 0.15);
}
.wh-form.wh-styledinput .wh-form__dateinputgroup input,
.wh-form.wh-styledinput .wh-form__timeinputgroup input {
  max-height: 41px;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--upload .wh-form__uploadfield {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  position: relative;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--upload .wh-form__button {
  height: 44px;
  padding-top: 12px;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--upload .wh-form__uploadfielddelete {
  background-color: transparent;
  height: 100%;
  align-items: center;
  padding-right: 5px;
  cursor: pointer;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--upload .wh-form__uploadfielddelete::after {
  width: 20px;
  height: 20px;
  color: #979797;
  font-weight: 900;
  border-radius: 50%;
  background-color: #fff;
  font-size: 20px;
  font-family: "Font Awesome 5 Pro";
  content: "\f057";
}
.wh-form.wh-styledinput .wh-form__fieldgroup--upload .wh-form__uploadfield--hasfile .wh-form__uploadfielddelete {
  display: flex;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--upload .wh-form__uploadfieldinputholder {
  display: block;
  flex-grow: 100;
  position: relative;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--upload .wh-form__uploadfieldinputholder input {
  width: 100%;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--upload .wh-form__uploadfieldselect {
  margin-left: 15px;
}
.wh-form.wh-styledinput .wh-form--uploading .wh-form__uploadfieldfilename {
  background-image: linear-gradient(135deg, rgba(27, 36, 83, 0.8) 16.67%, transparent 16.67%, transparent 50%, rgba(27, 36, 83, 0.8) 50%, rgba(27, 36, 83, 0.8) 66.67%, transparent 66.67%);
  background-size: 84.85px 84.85px;
  animation: movebackground 2s linear infinite;
}
.wh-form.wh-styledinput textarea {
  min-height: 140px;
}
.wh-form.wh-styledinput .wh-rtd__editor {
  width: 100%;
  min-height: 200px;
  border: 1px solid #979797;
  border-radius: 3px;
}
.wh-form.wh-styledinput .wh-rtd__editor:focus-within {
  border-color: #283477;
  box-shadow: 0 0 0 3px rgba(40, 52, 119, 0.15);
}
.wh-form.wh-styledinput .wh-rtd__editor .wh-rtd__stylescope {
  padding: 5px 8px;
}
.wh-form.wh-styledinput .wh-rtd__editor .wh-rtd-toolbar {
  border-radius: 3px 3px 0 0;
}
.wh-form.wh-styledinput .wh-rtd__editor .wh-rtd-toolbar select {
  height: 23px;
  border-color: #aaa;
  border-radius: 1px;
  font-size: 15px;
  padding: 0 10px;
  min-height: 22px;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--error input, .wh-form.wh-styledinput .wh-form__fieldgroup--error select,
.wh-form.wh-styledinput .wh-form__fieldgroup--error textarea,
.wh-form.wh-styledinput .wh-form__fieldgroup--error .wh-form__dateinputgroup,
.wh-form.wh-styledinput .wh-form__fieldgroup--error .wh-form__timeinputgroup {
  border-color: #D50000;
}
.wh-form.wh-styledinput .wh-form__error {
  display: none;
  flex: none;
  width: 100%; /* force error text below item */
  color: #D50000;
  padding: 8px 0 0;
  font-size: 15px;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--error .wh-form__error {
  display: block;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--radiogroup .wh-form__fieldline,
.wh-form.wh-styledinput .wh-form__fieldgroup--checkboxgroup .wh-form__fieldline {
  align-items: baseline;
}
.wh-form.wh-styledinput .wh-form__pulldown-styled {
  position: relative;
  flex: 1;
}
.wh-form.wh-styledinput .wh-form__pulldown-styled select {
  padding-right: 35px;
  width: 100%;
}
.wh-form.wh-styledinput .wh-form__pulldown-styled select:disabled {
  opacity: 0.4;
}
.wh-form.wh-styledinput .wh-form__pulldown-styled select:disabled + .arrow {
  opacity: 0.4;
}
.wh-form.wh-styledinput .wh-form__pulldown-styled select::-ms-expand {
  display: none; /* hide select arrow in IE */
}
.wh-form.wh-styledinput .wh-form__pulldown-styled .arrow {
  font-size: 22px;
  position: absolute;
  right: 15px;
  top: 50%;
  color: #979797;
  margin-top: -10px;
  pointer-events: none;
}
.wh-form.wh-styledinput .wh-form__pulldown-styled select[disabled] + .arrow {
  opacity: 0.2;
}
.wh-form.wh-styledinput .wh-form__pulldown-styled::after {
  display: block;
  content: "";
  width: 25px;
  position: absolute;
  z-index: 2; /* On top of select */
  right: 28px;
  top: 3px;
  bottom: 3px;
  pointer-events: none;
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#00ffffff", endColorstr="#ffffff",GradientType=1 ); /* IE6-9 */
}
.wh-form.wh-styledinput .wh-form__pulldown-styled--disabled::after {
  display: none;
}
.wh-form.wh-styledinput input[type=radio],
.wh-form.wh-styledinput input[type=checkbox] {
  display: block;
  position: absolute;
  left: -9999px;
  width: 0px;
  height: 0px;
}
.wh-form.wh-styledinput input[type=radio] + label,
.wh-form.wh-styledinput input[type=checkbox] + label {
  vertical-align: top;
  cursor: pointer;
  position: relative;
  top: 6px;
  width: 24px;
  max-width: 24px;
  flex: 0 0 24px;
  height: 24px;
  border: 1px solid #979797;
  background-color: #fff;
  border-radius: 3px;
}
.wh-form.wh-styledinput input[type=radio] + label::after,
.wh-form.wh-styledinput input[type=checkbox] + label::after {
  font-family: "Font Awesome 5 Pro";
  content: "";
  font-weight: 700;
  position: absolute;
  top: 0;
  left: 0;
  color: #979797;
  font-size: 16px;
  display: block;
  padding: 3px 0 0 3px;
  width: 100%;
  height: 100%;
  line-height: 16px;
  transition: all 0.3s;
}
.wh-form.wh-styledinput input[type=radio]:focus + label,
.wh-form.wh-styledinput input[type=checkbox]:focus + label {
  border-color: #283477;
  box-shadow: 0 0 0 3px rgba(40, 52, 119, 0.15);
}
.wh-form.wh-styledinput input[type=radio][disabled] + label,
.wh-form.wh-styledinput input[type=checkbox][disabled] + label {
  border-color: #C5C5C5;
  cursor: default;
}
.wh-form.wh-styledinput input[type=radio][disabled] + label + *,
.wh-form.wh-styledinput input[type=checkbox][disabled] + label + * {
  color: #979797;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--radiogroup .wh-form__fieldline + .wh-form__fieldline,
.wh-form.wh-styledinput .wh-form__fieldgroup--checkboxgroup .wh-form__fieldline + .wh-form__fieldline {
  margin-top: 10px;
}
.wh-form.wh-styledinput .wh-form__optiondata > * {
  padding-left: 15px;
}
.wh-form.wh-styledinput .wh-form__optiondata--horizontal {
  margin-right: 30px;
  width: 100%;
}
.wh-form.wh-styledinput .wh-form__optiondata--horizontal:last-child {
  margin-right: 0;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--horizontal > .wh-form__fields {
  margin-left: -20px;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--horizontal > .wh-form__fields > .wh-form__fieldline {
  padding-left: 20px;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--horizontal .wh-form__fieldline--nowrap {
  margin-top: 10px;
}
.wh-form.wh-styledinput input[type=radio] + label {
  border-radius: 50%;
}
.wh-form.wh-styledinput input[type=radio] + label::after {
  border: 4px solid #fff;
  border-radius: 50%;
}
.wh-form.wh-styledinput input[type=radio]:checked + label::after {
  background-color: #283477;
}
.wh-form.wh-styledinput input[type=radio]:checked + label {
  border-color: #283477;
}
.wh-form.wh-styledinput input[type=radio][disabled] + label::after {
  background-color: #F2F2F2;
  border-color: #F2F2F2;
}
.wh-form.wh-styledinput input[type=radio][disabled]:checked + label::after {
  background-color: #C5C5C5;
}
.wh-form.wh-styledinput input[type=checkbox]:checked + label::after {
  content: "\f00c";
  color: #283477;
}
.wh-form.wh-styledinput input[type=checkbox]:checked + label {
  border-color: #283477;
}
.wh-form.wh-styledinput input[type=checkbox][disabled] + label::after {
  content: "";
}
.wh-form.wh-styledinput input[type=checkbox][disabled] + label {
  background-color: #F2F2F2;
}
.wh-form.wh-styledinput input[type=checkbox][disabled]:checked + label::after {
  content: "\f00c";
  color: #C5C5C5;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--error input[type=checkbox]:not(:checked) + label,
.wh-form.wh-styledinput .wh-form__fieldgroup--error input[type=radio]:not(:checked) + label {
  border-color: #D50000;
}
.wh-form.wh-styledinput .wh-form__buttongroup {
  text-align: right;
  padding: 30px 0 20px;
  margin-top: 30px;
  border-top: 1px solid #C5C5C5;
}
.wh-form.wh-styledinput .wh-form__buttongroup::after {
  content: "";
  display: block;
  clear: both;
}
.wh-form.wh-styledinput .wh-form__buttongroup .wh-form__button {
  min-width: 200px;
}
.wh-form.wh-styledinput .wh-form__buttongroup .wh-form__button:hover {
  opacity: 0.8;
}
.wh-form.wh-styledinput .wh-form__buttongroup .wh-form__button + .wh-form__button {
  margin-left: 15px;
}
.wh-form.wh-styledinput .wh-form__buttongroup .wh-form__button--previous {
  float: left;
  border: 1px solid #283477;
  color: #283477;
  padding-right: 15px;
  padding-left: 35px;
  text-align: right;
  font-weight: 600;
}
.wh-form.wh-styledinput .wh-form__buttongroup .wh-form__button--previous::after {
  right: auto;
  font-weight: 400;
  left: 15px;
  content: "\f104";
}

.button, .wh-form__button {
  text-decoration: none;
  color: inherit;
  font: inherit;
  display: inline-block;
  border-radius: 3px;
  height: 48px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  line-height: 20px;
  padding: 14px 35px 14px 15px;
  position: relative;
  background-color: #283477;
  color: #fff;
  text-align: left;
  transition: background-color 0.3s;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  -webkit-appearance: none;
  margin: 0;
  border: 0 none;
}
.button .wh-form__buttonlabel, .button__label, .wh-form__button .wh-form__buttonlabel, .wh-form__button__label {
  position: relative;
  display: block;
}
.button::after, .wh-form__button::after {
  top: 50%;
  position: absolute;
  margin-top: -8px;
  right: 15px;
  display: inline-block;
  content: "\f105";
  font-size: 20px;
  font-family: "Font Awesome 5 Pro";
}

.button--outline, .wh-form__button--previous {
  box-shadow: none;
  background-color: transparent;
  border: 1px solid #fff;
}
.button--outline .wh-form__buttonlabel, .button--outline .button__label, .wh-form__button--previous .wh-form__buttonlabel, .wh-form__button--previous .button__label {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.button--outline::before, .wh-form__button--previous::before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.button--outline:hover::before, .wh-form__button--previous:hover::before {
  opacity: 1;
}

.button--outline.button--blue {
  border: 1px solid #283477;
  color: #283477;
}

@keyframes movebackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 84.85px 84.85px;
  }
}