@media (max-width: 760px) {
  html.programmadetailpage .pageimage {
    margin-bottom: 0;
  }
  html.programmadetailpage .pageimage + article {
    margin-top: 0;
  }
  html.programmadetailpage .pagedate {
    display: none;
  }
}

/* filterform programme overview */
.programma-filterform {
  margin-top: 20px;
  margin-bottom: 30px;
}
.programma-filterform + article {
  margin-top: 0;
}
.programma-filterform form {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.programma-filterform__count {
  flex-basis: 100%;
  order: 1;
}
.programma-filterform__link {
  flex: none;
  white-space: nowrap;
  margin-right: 30px;
  text-decoration: none;
  line-height: 20px;
  order: 1;
}
.programma-filterform__link .text {
  padding-right: 10px;
}
.programma-filterform__link i {
  font-size: 20px;
  position: relative;
  top: 2px;
}
.programma-filterform__link i + .text {
  padding-right: 0;
  padding-left: 10px;
}
.programma-filterform__themefilter {
  order: 2;
  min-width: 245px;
  max-width: 245px;
}
.programma-filterform__themefilter.wh-form__pulldown-styled > .arrow {
  color: #138f9b !important;
}
@media (max-width: 700px) {
  .programma-filterform {
    margin-bottom: 20px;
  }
  .programma-filterform form {
    flex-wrap: wrap;
  }
  .programma-filterform__count {
    flex-basis: 50%;
  }
  .programma-filterform__link {
    text-align: right;
    flex-basis: 50%;
    margin-right: 0;
  }
  .programma-filterform__themefilter {
    order: 0;
    min-width: 100%;
    max-width: 100%;
    margin-bottom: 15px;
  }
}

.programma-orderbtn {
  background-color: #FEBE26;
  text-decoration: none;
  text-transform: uppercase;
  display: block;
  margin-bottom: 20px;
  position: relative;
  padding: 20px 40px 18px 20px;
  color: #000;
  font: 700 20px/30px "Roboto Condensed", sans-serif, serif, Arial;
  transition: opacity 0.2s;
}
.programma-orderbtn:hover {
  opacity: 0.85;
}
.programma-orderbtn i {
  position: absolute;
  top: 50%;
  right: 20px;
  font-size: 30px;
  margin-top: -15px;
}
@media (max-width: 760px) {
  .programma-orderbtn {
    margin-bottom: 0;
    padding: 10px 40px 8px 20px;
  }
}

.programma-reservationcosts {
  font-size: 14px;
  margin-bottom: 20px;
  font-style: italic;
}

.programmadetails {
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.24);
  background-color: #f2f2f2;
  border: 1px solid #E4E4E4;
}
.programmadetails dl {
  font: normal 16px/120% "Roboto Condensed", sans-serif, serif, Arial;
  padding: 20px 15px;
  margin: 0;
}
.programmadetails dt, .programmadetails dd {
  display: block;
  padding: 0;
  margin: 0;
  vertical-align: top;
}
.programmadetails dt {
  color: #138f9b;
  font-weight: 700;
  padding-bottom: 3px;
}
.programmadetails dd + dt {
  padding-top: 10px;
}
.programmadetails .date > span {
  display: inline-block;
  white-space: nowrap;
  margin-right: 4px;
}
.programmadetails dd a {
  color: #138f9b;
  white-space: nowrap;
  max-width: 100%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.programmadetails dd a i {
  display: inline-block;
  position: relative;
  top: 2px;
  padding-right: 6px;
  font-size: 15px;
}
@media (max-width: 760px) {
  .programmadetails {
    box-shadow: none;
  }
  .programmadetails dl {
    font-size: 15px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 20px;
  }
  .programmadetails dt {
    flex: none;
    width: 100px;
    padding-right: 10px;
  }
  .programmadetails dd + dt, .programmadetails dd + dt + dd {
    margin-top: 7px;
  }
  .programmadetails dd {
    flex-basis: 100%;
    max-width: calc(100% - 100px);
  }
  .programmadetails dd + dt {
    padding-top: 0;
  }
}

.programmaoverview .showmoreresults {
  text-align: center;
  margin-top: 50px;
}
.programmaoverview .showmoreresults span {
  display: inline-block;
  cursor: pointer;
  color: #138f9b;
  border-radius: 3px;
  border: 1px solid #138f9b;
  font: normal 20px/20px "Roboto Condensed", sans-serif, serif, Arial;
  padding: 14px 40px 14px 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  width: 320px;
}
.programmaoverview .showmoreresults span i {
  position: absolute;
  right: 20px;
  top: 50%;
  font-size: 20px;
  margin-top: -9px;
}

/* next is also used by performances widget */
.performancelist ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.performance-item {
  display: flex;
  flex-flow: row;
  height: 195px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.24);
  background-color: #fff;
  margin-bottom: 25px;
}
.performance-item.hide {
  display: none;
}
.performance-item__date {
  background-color: #C71F2D;
  color: #fff;
  font-family: "Roboto Condensed", sans-serif, serif, Arial;
  padding: 15px 10px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  text-transform: uppercase;
  flex: none;
  min-height: 50px;
  width: 95px;
}
.performance-item__date > span {
  display: block;
  line-height: 110%;
}
.performance-item__date .weekday {
  font-size: 26px;
}
.performance-item__date .day {
  font-size: 75px;
  font-weight: bold;
}
.performance-item__date .month {
  font-size: 28px;
}
.performance-item__date .year {
  font-size: 16px;
  padding-top: 10px;
}
.performance-item__image {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 195px;
  flex: none;
}
.performance-item__image > img {
  height: 100%;
  width: auto;
  position: absolute;
  top: 0;
  right: -9999px;
  bottom: 0;
  left: -9999px;
  margin: 0 auto;
}
.performance-item__content {
  flex: 1 1 100%;
  padding: 10px 20px;
  max-height: 100%;
  position: relative;
  overflow: hidden;
  border-top: 1px solid #E4E4E4;
  border-bottom: 1px solid #E4E4E4;
}
.performance-item__content::after {
  display: block;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30px;
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 60%, rgb(255, 255, 255) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 60%, rgb(255, 255, 255) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 60%, rgb(255, 255, 255) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#00ffffff", endColorstr="#ffffff",GradientType=0 ); /* IE6-9 */
}
.performance-item__content .meta {
  font: bold 16px/19px "Roboto Condensed", sans-serif, serif, Arial;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  padding-bottom: 15px;
}
.performance-item__content .meta > span {
  display: inline-block;
}
.performance-item__content .meta .time_location {
  color: #C71F2D;
  padding-right: 10px;
}
.performance-item__content .meta .theme {
  color: #138f9b;
}
.performance-item__content .meta .theme i {
  padding-right: 5px;
}
.performance-item__content .title {
  font: bold 26px/27px "Roboto Condensed", sans-serif, serif, Arial;
}
.performance-item__content .description {
  padding-top: 7px;
  font-size: 13px;
  line-height: 24px;
}
.performance-item__buttons {
  color: #fff;
  background-color: #446286;
  font: bold 20px/24px "Roboto Condensed", sans-serif, serif, Arial;
  text-transform: uppercase;
  flex: none;
  width: 160px;
}
.performance-item__buttons a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: inherit;
  text-decoration: none;
  height: 100%;
  transition: opacity 0.2s;
}
.performance-item__buttons a:hover {
  opacity: 0.85;
}
.performance-item__buttons a.orderlink {
  background-color: #FEBE26;
  color: #202020;
  max-height: 50%;
}
.performance-item__buttons a.freeaccess {
  background-color: #50b9c4;
  padding: 0 15px;
  max-height: 50%;
}
.performance-item__buttons a i {
  font-size: 26px;
  display: inline-block;
  position: relative;
  top: 3px;
  padding-left: 7px;
}
.performance-item__buttons a + a {
  max-height: 50%;
}
.performance-item__buttons a > span {
  display: inline-block;
}
@media (max-width: 1000px) {
  .performance-item__content .meta {
    display: block;
  }
  .performance-item__content .meta .time_location {
    padding-right: 0;
    display: block;
  }
  .performance-item__content .meta .theme {
    padding-top: 5px;
    display: block;
  }
}
@media (max-width: 900px) {
  .performance-item__image {
    display: none;
  }
}
@media (max-width: 750px) {
  .performance-item {
    display: block;
    height: auto;
    margin-left: -30px;
    margin-right: -30px;
  }
  .performance-item__date {
    padding: 12px 30px 8px;
    display: block;
    width: 100%;
    text-align: left;
  }
  .performance-item__date > span {
    display: inline-block;
    margin-right: 7px;
    vertical-align: middle;
  }
  .performance-item__date .weekday {
    font-size: 21px;
  }
  .performance-item__date .day {
    font-size: 28px;
  }
  .performance-item__date .month {
    font-size: 21px;
  }
  .performance-item__date .year {
    font-size: 21px;
    padding-top: 0;
  }
  .performance-item__content {
    max-height: 300px;
  }
  .performance-item__content .meta {
    padding-bottom: 5px;
    font-size: 15px;
  }
  .performance-item__content .meta .theme {
    font-size: 14px;
  }
  .performance-item__content .title {
    font-size: 20px;
  }
  .performance-item__buttons {
    width: 100%;
    display: flex;
  }
  .performance-item__buttons a {
    flex: 1 1 100%;
    height: auto;
    min-height: 50px;
  }
  .performance-item__buttons a.orderlink, .performance-item__buttons a.freeaccess, .performance-item__buttons a + a {
    max-height: 100%;
  }
}