.widget-slideshare {
  margin-bottom: 30px;
  position: relative;
  background-color: #202020;
  color: #fff;
}
.widget-slideshare::after {
  content: "";
  display: block;
  padding-top: 81.41%;
}
.widget-slideshare iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}