/*
$color_darkblue: #446286;
$color_red: #C71F2D;
$color_yellow: #FEBE26;
*/

.widget-cta--center
{
  text-align: center;
}
.widget-cta--right
{
  text-align: right;
}

.widget-cta__button
{
  font: bold 16px/28px 'Roboto Condensed', sans-serif, serif, Arial;
  text-decoration: none;
  display: inline-block;
  position: relative;
  background-color: #446286;
  border-radius: 0;
  text-align: left;
  height: 48px;
  padding: 10px 20px;
  color: #fff;
  min-width: 255px;
  max-width: 100%;
  transition: opacity 0.3s;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.27);
  border-radius: 2px;
  cursor: pointer;
  -webkit-appearance: none;
  margin: 0;
  border: 0 none;
}
.widget-cta__button--big
{
  font-size: 20px;
  line-height: 30px;
  height: 69px;
  text-transform: uppercase;
  padding: 21px 20px 15px;
}
.widget-cta__button .title
{
  display: block;
  vertical-align: top;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 100%;
}
.widget-cta__button:hover
{
  opacity: 0.85;
}
.widget-cta__button .icon
{
  position: absolute;
  font-size: 17px;
  right: 20px;
  top: 50%;
  margin-top: -11px;
  font-size: 24px;
}
.widget-cta__button .icon + .title
{
  padding-right: 35px;
}

.widget-cta__button--outline
{
  color: #446286;
  border: 2px solid #446286;
  background-color: #fff;
  box-shadow: none;
  transition: color 0.3s, border-color 0.3s;
}
.widget-cta__button--big.widget-cta__button--outline
{
  padding-top: 19px;
}

.widget-cta__button--text
{
  color: #446286;
  background-color: transparent;
  transition: color 0.3s;
  box-shadow: none;
  min-width: 0;
  padding: 5px 0;
}
.widget-cta__button--text .icon
{
  right: auto;
  left: 0;
  top: 50%;
  margin-top: -12px;
  font-size: 24px;
}
.widget-cta__button--text .icon + .title
{
  padding: 5px 0 0 20px;
}

.widget-cta__button--red
{
  background-color: #C71F2D;
}
.widget-cta__button--red.widget-cta__button--outline
{
  background-color: #fff;
  color: #C71F2D;
  border-color: #C71F2D;
}
.widget-cta__button--red.widget-cta__button--text
{
  background-color: transparent;
  color: #C71F2D;
}

.widget-cta__button--yellow
{
  background-color: #FEBE26;
  color: inherit;
}
.widget-cta__button--yellow.widget-cta__button--outline
{
  background-color: #fff;
  color: #FEBE26;
  border-color: #FEBE26;
}
.widget-cta__button--yellow.widget-cta__button--text
{
  background-color: transparent;
  color: #FEBE26;
}
