/* path */
.pathnav {
  color: #7C7C7C;
  margin-bottom: 20px;
  flex: none;
}
.pathnav ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  line-height: 20px;
  font-size: 15px;
}
.pathnav ol.overflow {
  text-overflow: initial;
}
.pathnav li {
  display: inline;
}
.pathnav li.hide {
  display: none;
}
.pathnav li + li::before {
  font-family: "Font Awesome 5 Pro";
  content: "\f105";
  font-weight: 300;
  font-size: 16px;
  position: relative;
  top: 2px;
  vertical-align: bottom;
  padding: 0 7px;
  display: inline-block;
}
.pathnav ol.shorten > li:first-child::after {
  font-family: "Font Awesome 5 Pro";
  content: "\f105  ...";
  font-weight: 300;
  font-size: 16px;
  position: relative;
  top: 2px;
  vertical-align: bottom;
  padding-left: 7px;
  display: inline-block;
}
.pathnav a {
  color: inherit;
  text-decoration: none;
}
.pathnav a:hover {
  text-decoration: underline;
}

/*
@media(max-width: 700px)
{
  .pathnav
  {
    display: none;
  }
}
*/
footer {
  min-height: 100px;
  padding-top: 40px;
  font-family: "Roboto Condensed", sans-serif, serif, Arial;
}
footer a {
  color: inherit;
  text-decoration: none;
}
footer a:hover {
  text-decoration: underline;
}
footer ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
footer .footer__text {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 790px;
  font-size: 14px;
  line-height: 19px;
  color: #7D848F;
  text-align: center;
}
@media (max-width: 600px) {
  footer .footer__text {
    padding-left: 20px;
    padding-right: 20px;
  }
}
footer .footer__text > *:last-child {
  margin-bottom: 0;
}
footer .footer__companies {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 1170px;
  margin-top: 40px;
  margin-bottom: 40px;
}
@media (max-width: 600px) {
  footer .footer__companies {
    padding-left: 20px;
    padding-right: 20px;
  }
}
footer .footer__companies ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: center;
  margin-left: -30px;
}
footer .footer__companies ul li {
  display: inline-block;
}
footer .footer__companies a {
  display: inline-block;
  margin-bottom: 20px;
  margin-left: 30px;
}
footer .footer__nav {
  background-color: #394B61;
  color: #fff;
  padding: 30px 0;
}
footer .footer__nav .centercontent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
html.mobilelayout footer .footer__nav .centercontent {
  flex-wrap: wrap;
}
footer .footer__nav .logo {
  display: inline-block;
  flex: none;
  width: 125px;
  height: 47px;
  margin-right: 30px;
}
footer .footer__nav .newsletterform {
  position: relative;
  font-size: 16px;
  flex: none;
  width: 315px;
  margin: 20px 0 10px;
}
@media (max-width: 520px) {
  footer .footer__nav .newsletterform {
    width: 100%;
    margin-bottom: 0;
  }
}
footer .footer__nav .newsletterform > * {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  height: 45px;
  border: 0 none;
  font-size: 16px;
  border-radius: 0;
  font: inherit;
  color: inherit;
  background-color: transparent;
}
footer .footer__nav .newsletterform input {
  width: 100%;
  padding: 12px 50px 12px 15px;
  line-height: 20px;
  border-radius: 100px;
  border: 1px solid rgba(60, 210, 224, 0.5);
  transition: border-color 0.2s;
}
footer .footer__nav .newsletterform input:focus {
  border-color: rgb(60, 210, 224);
}
footer .footer__nav .newsletterform button {
  position: absolute;
  right: 8px;
  top: 1px;
  width: 45px;
  font-size: 17px;
  display: inline-block;
  cursor: pointer;
}
footer .footer__nav .mainnav {
  margin-left: 30px;
  margin-right: -30px;
  display: block;
  text-transform: uppercase;
  font-size: 20px;
  line-height: 24px;
  text-align: right;
}
html.mobilelayout footer .footer__nav .mainnav {
  display: none;
}
footer .footer__nav .mainnav a {
  opacity: 0.5;
  transition: opacity 0.2s;
}
footer .footer__nav .mainnav a:hover {
  opacity: 1;
  text-decoration: none;
}
footer .footer__nav .mainnav li {
  display: inline-block;
  margin: 2px 30px 2px 0;
}
footer .footer__bottombar {
  font-size: 12px;
  padding: 9px 0;
  border-top: #fff;
  color: #fff;
}
footer .footer__bottombar .centercontent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media (max-width: 520px) {
  footer .footer__bottombar .centercontent {
    justify-content: center;
  }
}
footer .footer__bottombar .socialnav {
  color: #394B61;
  font-size: 16px;
  margin: 3px 0;
  margin-right: 10px;
}
@media (max-width: 520px) {
  footer .footer__bottombar .socialnav {
    position: absolute;
    right: 15px;
    margin-top: -180px;
  }
}
footer .footer__bottombar .socialnav li {
  display: inline-block;
  padding: 0 7px;
}
footer .footer__bottombar .socialnav a {
  background-color: #fff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: inline-block;
  line-height: 20px;
  padding-top: 3px;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.2s;
}
footer .footer__bottombar .socialnav a:hover {
  text-decoration: none;
  opacity: 1;
}
footer .footer__bottombar {
  background-color: #394B61;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}

.footer__bottombar .bottomnav,
.mobilenav-footer .bottomnav {
  margin: 3px 10px 3px -12px;
}
.footer__bottombar .bottomnav li,
.mobilenav-footer .bottomnav li {
  display: inline-block;
  margin-left: 12px;
}
.footer__bottombar .bottomnav li::before,
.mobilenav-footer .bottomnav li::before {
  content: "|";
  padding-right: 12px;
  display: inline-block;
}
.footer__bottombar .bottomnav li.copyright::before,
.mobilenav-footer .bottomnav li.copyright::before {
  display: none;
}

.footer__bottombar .webhare,
.mobilenav-footer .webhare {
  margin: 3px 0;
}
.footer__bottombar .webhare > span,
.mobilenav-footer .webhare > span {
  vertical-align: middle;
  line-height: 16px;
  padding-right: 7px;
}
.footer__bottombar .webhare > span:first-child,
.mobilenav-footer .webhare > span:first-child {
  opacity: 0.5;
  transition: opacity 0.2s;
}
.footer__bottombar .webhare:hover span,
.mobilenav-footer .webhare:hover span {
  opacity: 1;
}
.footer__bottombar .webhare img,
.mobilenav-footer .webhare img {
  vertical-align: middle;
}