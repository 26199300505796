html {
  background-color: #fff;
  min-height: 100%;
  display: flex;
  flex-direction: row; /* IE10/11 Workaround for child elements with flex-direction: column to outfill content area (main) */
}
html.homepage, html.programmapage {
  background-color: #f2f2f2;
}

body {
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  width: 100vw;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}
html.mobilelayout.showmobilemenu body, html.hidescroll body {
  overflow: hidden;
}

header, footer {
  flex: none;
}

main {
  flex: 1 0 auto; /* vertical (column) outfill */
  max-width: 100%;
  padding: 0;
  position: relative;
}

html:not(.mobilelayout) #articleanchor + h1 {
  padding-left: 140px;
  padding-right: 140px;
  padding-top: 20px;
}

.pageimage + article {
  margin-top: 30px;
}

html.searchpage main {
  padding-top: 35px;
}

article > * {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 790px;
}
@media (max-width: 600px) {
  article > * {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.centercontent,
article > .xwidecontent,
.fullwidth > .xwidecontent {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 1170px;
}
@media (max-width: 600px) {
  .centercontent,
  article > .xwidecontent,
  .fullwidth > .xwidecontent {
    padding-left: 20px;
    padding-right: 20px;
  }
}

article > .fullwidth {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.pagetitlebar {
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
}
.pagetitlebar h1, .pagetitlebar h2 {
  color: #fff;
  margin-bottom: 0;
}
.pagetitlebar + p {
  margin-top: 30px;
}

p.intro, h1 {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 980px;
}
@media (max-width: 600px) {
  p.intro, h1 {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.wh-anchor, .wh-rtd__anchor {
  display: inline-block;
  position: absolute;
  transform: translateY(-102px);
}
html.mobilelayout .wh-anchor, html.mobilelayout .wh-rtd__anchor {
  transform: translateY(-42px);
}

article .pagedate {
  color: #138f9b;
  padding-top: 10px;
  font: 400 20px/140% "Roboto Condensed", sans-serif, serif, Arial;
  text-align: center;
  text-transform: uppercase;
}
article .pagedate > span {
  display: inline-block;
}
article .pagedate > span + span {
  padding-left: 7px;
}
article .pagedate .date {
  font-weight: bold;
}
article .pagedate .time {
  color: #202020;
}

.contents__twocols {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 1170px;
  display: flex;
  flex-flow: row;
  padding-top: 30px;
}
@media (max-width: 600px) {
  .contents__twocols {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.contents__twocols--right {
  order: 2;
  margin-left: 30px;
  flex: none;
  width: 255px;
}
.contents__twocols--left {
  flex-basis: 100%;
}
@media (max-width: 760px) {
  .contents__twocols {
    display: block;
    padding: 0;
  }
  .contents__twocols--left {
    padding: 0 20px;
  }
  .contents__twocols--right {
    margin-left: 0;
    width: 100%;
    margin-bottom: 20px;
  }
}

.pagecontentlist::before {
  content: "";
  display: block;
  border-top: 1px solid #C5C5C5;
}

@media (max-width: 700px) {
  article {
    margin-top: 20px;
  }
}
/* contentlist */
.contentlist {
  list-style-type: none;
  margin: 30px 0 15px;
  padding: 0;
}
.contentlist a {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: inherit;
  text-decoration: none;
  position: relative;
}
.contentlist a::before {
  content: "";
  display: block;
  position: absolute;
  top: -15px;
  right: -30px;
  bottom: -15px;
  left: -30px;
  background-color: #F3F3F1;
  opacity: 0;
  transition: opacity 0.2s;
  z-index: -1;
  border-radius: 3px;
}
.contentlist a:hover::before {
  opacity: 1;
}
.contentlist__item + .contentlist__item {
  margin-top: 30px;
}
.contentlist__item__text > * {
  display: block;
  line-height: 28px;
}
.contentlist__item__text__date {
  color: #4078C5;
  margin-top: -8px;
  vertical-align: top;
}
.contentlist__item__text__title {
  color: #283477;
  font: bold 26px/140% "Roboto Condensed", sans-serif, serif, Arial;
}
.contentlist__item__text__description {
  padding-top: 5px;
}
.contentlist__item__image {
  display: block;
  margin-left: auto;
  flex: none;
  width: 180px;
  padding-left: 20px;
}
@media (max-width: 600px) {
  .contentlist__item a::before {
    right: -20px;
    left: -20px;
  }
  .contentlist__item__image {
    display: none;
  }
}

/* page image */
.pageimage {
  margin-top: -105px; /* height header, desktop layout */
  margin-bottom: 20px;
  color: #fff;
  position: relative;
  z-index: 1;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  height: 40vw;
  max-height: 560px;
}
.pageimage::before {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
  display: block;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%;
}
html.mobilelayout .pageimage {
  margin-top: 0;
}
.pageimage .content {
  position: absolute;
  top: 102px;
  left: 0;
  right: 0;
  bottom: 30px;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  align-items: center;
  font: 400 24px/120% "Roboto Condensed", sans-serif, serif, Arial;
}
.pageimage .content .description + .heading1 {
  margin-top: 10px;
}
html.mobilelayout .pageimage .content {
  justify-content: center;
  top: 30px;
  bottom: 30px;
}
html.mobilelayout .pageimage .content .description {
  display: none;
}
.pageimage h1 {
  color: inherit;
}
.pageimage h2 {
  font-size: 42px;
  line-height: 115%;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 790px;
}
@media (max-width: 600px) {
  .pageimage h2 {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.pageimage .description {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 980px;
}
@media (max-width: 600px) {
  .pageimage .description {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.pageimage h1, .pageimage h2, .pageimage .description, .pageimage a {
  text-align: center;
  color: inherit;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
}
.pageimage .pagedate {
  position: absolute;
  bottom: -22px;
  left: 50%;
  transform: translateX(-50%);
  text-transform: uppercase;
  color: #fff;
  background-color: #202020;
  height: 48px;
  font: 400 20px/28px "Roboto Condensed", sans-serif, serif, Arial;
  padding: 10px 17px;
  white-space: nowrap;
}
.pageimage .pagedate > span {
  display: inline-block;
}
.pageimage .pagedate > span + span {
  padding-left: 7px;
}
.pageimage .pagedate .date, .pageimage .pagedate .time {
  font-weight: 700;
}
.pageimage .pagedate .time {
  color: #3CD2E0;
}

/* horizontal perfect slider scrollbar */
.horizontal-scroller.nativescroll {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.horizontal-scroller.ps-container .ps-scrollbar-x-rail {
  opacity: 1;
  display: none;
  background-color: rgba(255, 255, 255, 0.3) !important;
  position: relative;
  height: 5px;
  border-radius: 3px;
}
.horizontal-scroller.ps-container .ps-scrollbar-x-rail::before {
  content: "";
  background-color: transparent;
  height: 35px;
  position: absolute;
  top: -15px;
  left: 0;
  right: 0;
}
.horizontal-scroller.ps-container .ps-scrollbar-x-rail > .ps-scrollbar-x {
  height: 5px !important;
  border-radius: 3px;
  background-color: #fff !important;
  bottom: 0;
}
.horizontal-scroller.ps-container .ps-scrollbar-x-rail > .ps-scrollbar-x::before {
  position: absolute;
  content: "";
  display: block;
  left: 0;
  right: 0;
  top: -15px;
  left: 0;
  right: 0;
  height: 35px;
}
.horizontal-scroller.ps-container .ps-scrollbar-y-rail {
  display: none;
}
.horizontal-scroller.ps-active-x .ps-scrollbar-x-rail {
  display: block;
}

@-moz-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  60% {
    -moz-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}
@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  60% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  60% {
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}