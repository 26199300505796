.widget-performances {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 1170px;
  margin-bottom: 25px;
}
@media (max-width: 600px) {
  .widget-performances {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.widget-performances h2 {
  text-align: center;
  color: #138f9b;
}
.widget-performances .showfullprogramme {
  text-align: center;
}
.widget-performances .showfullprogramme--history {
  text-align: right;
}
.widget-performances .showfullprogramme a {
  display: inline-block;
  text-decoration: none;
  color: #138f9b;
  border-radius: 3px;
  border: 1px solid #138f9b;
  font: normal 20px/20px "Roboto Condensed", sans-serif, serif, Arial;
  padding: 14px 40px 14px 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  width: 320px;
}
.widget-performances .showfullprogramme a i {
  position: absolute;
  right: 20px;
  top: 50%;
  font-size: 26px;
  margin-top: -13px;
}
.widget-performances .showfullprogramme--history a {
  width: auto;
}