header {
  color: inherit;
  height: 85px;
  line-height: 24px;
  font-family: "Roboto Condensed", sans-serif, serif, Arial;
  z-index: 10;
  position: relative;
  background-color: rgba(60, 210, 224, 0.9);
}
html.mobilelayout header {
  height: 40px;
  background-color: #50B9C4;
}
header a {
  color: inherit;
  text-decoration: none;
}
header ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
header *[tabindex]:focus {
  outline: none;
}
header .mainnav {
  height: inherit;
  font-size: 20px;
  line-height: 30px;
  position: relative;
  z-index: 1; /* keep on top of topsearch */
}
header .mainnav .mobilenav-footer {
  display: none;
  margin-top: 30px;
  padding-top: 15px;
  border-top: 1px solid #fff;
  font-size: 12px;
}
html.mobilelayout.showmobilemenu header .mainnav {
  display: block;
}
html.mobilelayout.showmobilemenu header .mainnav .mobilenav-footer {
  display: block;
}
html.mobilelayout.showmobilemenu header .mainnav .logo {
  display: none;
}
html.fixedmenu header .mainnav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 85px;
  background-color: rgba(60, 210, 224, 0.9);
}
html.mobilelayout header .mainnav {
  display: none;
  position: fixed;
  top: 40px;
  height: auto;
  overflow: hidden;
  overflow-y: auto;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  background-color: #138F9B;
}
header .mainnav .togglesearch {
  transition: text-shadow 0.2s;
  cursor: pointer;
}
header .mainnav .togglesearch:hover {
  text-shadow: 0 0 1px #fff;
}
header .mainnav > div {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 1170px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 600px) {
  header .mainnav > div {
    padding-left: 20px;
    padding-right: 20px;
  }
}
header .mainnav > div > ul {
  white-space: nowrap;
  height: 58px;
}
header .mainnav > div > ul > li {
  display: inline-block;
  margin-left: 5px;
  position: relative;
  height: 100%;
}
header .mainnav > div > ul > li > .togglechildren {
  position: absolute;
  right: 0;
  top: 7px;
  font-size: 16px;
  display: none;
  cursor: pointer;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
}
header .mainnav > div > ul > li > a {
  display: inline-block;
  font-weight: bold;
  text-transform: uppercase;
  transition: all 0.2s;
  padding: 12px 20px;
  border: 2px solid transparent;
}
header .mainnav > div > ul > li:hover > a, header .mainnav > div > ul > li.active > a {
  border-color: #fff;
  color: #fff;
}
header .mainnav > div > ul > li.active > a {
  background-color: #C71F2D;
}
header .mainnav > div > ul > li .mainnav__children {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 245px;
  font: normal 16px/22px "Merriweather", serif, Arial;
  white-space: normal;
}
header .mainnav > div > ul > li .mainnav__children ul {
  margin-top: 13px;
  box-shadow: 0 2px 12px 7px rgba(0, 0, 0, 0.1);
  width: 100%;
  background-color: #138F9B;
  padding: 15px 0 20px;
}
header .mainnav > div > ul > li .mainnav__children li {
  display: block;
}
header .mainnav > div > ul > li .mainnav__children a {
  display: block;
  padding: 5px 20px;
  color: #fff;
}
header .mainnav > div > ul > li .mainnav__children li.active > a,
header .mainnav > div > ul > li .mainnav__children a:hover {
  text-decoration: underline;
}
header .mainnav > div > ul > li:hover > .mainnav__children, header .mainnav > div > ul > li > a:focus + .mainnav__children {
  display: block;
}
header .mainnav > div > ul > li.search {
  font-size: 16px;
  margin-left: 35px;
  transition: opacity 0.2s;
}
html.show-topsearch header .mainnav > div > ul > li.search {
  opacity: 0;
}
html.mobilelayout header .mainnav > div {
  display: block;
  font-size: 18px;
  line-height: 22px;
  height: auto;
  margin-top: 60px;
  font-size: 17px;
}
html.mobilelayout header .mainnav > div .search {
  display: none;
}
html.mobilelayout header .mainnav > div > ul {
  white-space: normal;
  padding-top: 15px;
  max-width: 100%;
  white-space: normal;
  height: auto;
}
html.mobilelayout header .mainnav > div > ul > li {
  display: block;
}
html.mobilelayout header .mainnav > div > ul > li > a {
  padding: 7px 0;
  border: 0 none;
}
html.mobilelayout header .mainnav > div > ul > li.active > a,
html.mobilelayout header .mainnav > div > ul > li a:hover {
  background-color: transparent;
  text-decoration: underline;
}
html.mobilelayout header .mainnav > div > ul > li > .togglechildren--show, html.mobilelayout header .mainnav > div > ul > li.showchildren > .togglechildren--hide, html.mobilelayout header .mainnav > div > ul > li:hover > .togglechildren--hide, html.mobilelayout header .mainnav > div > ul > li > a:focus ~ .togglechildren--hide {
  display: block;
}
html.mobilelayout header .mainnav > div > ul > li.showchildren > .togglechildren--show, html.mobilelayout header .mainnav > div > ul > li:hover > .togglechildren--show, html.mobilelayout header .mainnav > div > ul > li > a:focus ~ .togglechildren--show {
  display: none;
}
html.mobilelayout header .mainnav > div > ul > li .mainnav__children {
  position: relative;
  top: auto;
  left: auto;
  transform: none;
  width: 100%;
  font-size: 14px;
}
html.mobilelayout header .mainnav > div > ul > li .mainnav__children ul {
  padding: 0;
  background-color: transparent;
  box-shadow: none;
  margin: 0;
}
header .logo {
  display: inline-block;
  width: 190px;
  height: 190px;
  flex: none;
  position: relative;
  overflow: hidden;
  background-color: #C71F2D;
  align-self: flex-start;
}
header .logo > img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}
html.mobilelayout header .logo {
  position: absolute;
  left: 0;
  top: auto;
  bottom: 0;
  width: 76px;
  height: 100%;
}
html.mobilelayout header .logo > img {
  position: absolute;
  bottom: 3px;
  left: 0;
}
header .menutoggle {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: none;
  cursor: pointer;
  align-items: center;
  justify-content: flex-end;
  font-size: 16px;
  padding-right: 10px;
  text-align: center;
}
header .menutoggle .label {
  text-transform: uppercase;
  display: inline-block;
  font-size: 12px;
  padding-right: 10px;
}
header .menutoggle .show {
  width: 15px;
  display: inline-block;
}
header .menutoggle .hide {
  width: 15px;
  display: none;
}
html.mobilelayout header .menutoggle {
  display: flex;
}
html.showmobilemenu header .menutoggle .hide {
  display: inline-block;
}
html.showmobilemenu header .menutoggle .show {
  display: none;
}